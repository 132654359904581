import CodePuzzle from "./CodePuzzle";
import GameObject from "./GameObject";

export default class CodePuzzleImages2 extends CodePuzzle {
    constructor(config) {
        super(config);

        this.codeImages = config.codeImages;
        this.codeImagesGlow = config.codeImagesGlow;
        this.x_coords = config.x_coords;
        this.y_coords = config.y_coords;
    }

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("QuestMap");
        this.element.style.backgroundImage = `url('${this.src}')`;

        // Создаем отображение блоков c кодом
        for (let i=0; i<this.correctCode.length; i++) {

            let codePuzzleImage = new GameObject({
                name: `codePuzzleImage-${i}`,

                onClickEvents: [{type:'increaseCodeImage2', 
                                index: i, 
                                images: this.codeImages, 
                                possibleValues: this.possibleValues,
                                map: this}],

                map: this,
                src: this.codeImages[parseInt(this.currentCode[i])],
                src_glow: this.codeImagesGlow[parseInt(this.currentCode[i])],
                x: this.x_coords[parseInt(this.currentCode[i])],
                y: this.y_coords[parseInt(this.currentCode[i])],
            })
            codePuzzleImage.init({map: this,
                container:this.element})

        };

    };

    init(container) {
        this.createElement();
        container.insertBefore(this.element, container.firstChild);
        this.mountObjects();
        this.addNavigationElements();
    };
}