import QuestMap from "./QuestMap";
import NavigationButton from "./NavigationButton";

export default class Narration extends QuestMap {
    constructor(config) {
        super(config);

        this.displayText = config.displayText || [];
        // this.followingMap = config.followingMap || 'grandpaCabinet1';
        this.stageCounter = 0;
        this.onSolveEvents = config.onSolveEvents || [];
    }

    addNavigationElements() {

        console.log('inserting navigation elements')

        if (this.displayText.length > 0) {
            let navigationButton = new NavigationButton({
                direction: 'right',
                onClickEvents: [{
                                    type:'increaseNarrative', 
                                    map: this.name, 
                                    stageCounter: this.stageCounter, 
                                    displayText: this.displayText
                                }],
                map: this.name,
            })
    
            console.log('initializing navigation elements')
    
            navigationButton.init({map: this,
                container:this.element})
        }
    };

    reinitNavigationElements() {

        let navigationElements = document.querySelectorAll(".NavigationButton")

        navigationElements.forEach(ne => {
            ne.remove()
        })

        this.addNavigationElements()
    };

    init(container) {

        console.log(this)
        this.createElement();

        container.insertBefore(this.element, container.firstChild);

        this.addNavigationElements();
    };
}