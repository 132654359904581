import Auth from "./pages/Auth";
import Game from "./pages/Game";
import { GAME_ROUTE, LOGIN_ROUTE } from "./utils/consts";

export const authRoutes = [
    {
        path : GAME_ROUTE + '/:quest' + '/:room',
        Component: Game
    }
]

export const publicRoutes = [
    {
        path : LOGIN_ROUTE,
        Component: Auth 
    }
]