import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";

export const login = async (code, name) => {
    try {
        const {data} = await $host.post('api/questCode/login', {code, name})
        localStorage.setItem('token', data.token)
        return {data:jwt_decode(data.token), foundQuest:data.foundQuest, uuid:data.uuid}
    } catch (e) {
        console.log(e)
    }
}

export const check = async () => {
    try {
        const {data} = await $authHost.get('api/questCode/auth')
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    } catch (e) {
        console.log(e)
    }
}

export const getCode = async (uuid) => {
    try {
        const {data} = await $host.get(`api/user/getCode/${uuid}`)
        return {name: data.name, code: data.code}
    } catch (e) {
        console.log(e)
    }
}