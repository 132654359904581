import { makeAutoObservable } from "mobx"

export default class UserStore {
    constructor() {
        this.isAuth = false
        this.user = {}
        this.name = null
        this.code = null
        this.socket = null
        this.sessionid = null
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this.isAuth = bool
    }

    setUser(user) {
        this.user = user
    }

    setCode(code) {
        this.code = code
    }

    setName(name) {
        this.name = name
    }

    setSessionId(id) {
        this.sessionid = id
    }

    setSocket(socket) {
        this.socket = socket
    }

    // get isAuth() {
    //     return this._isAuth
    // }

    // get user() {
    //     return this._user
    // }
    // get code() {
    //     return this.code
    // }
}
