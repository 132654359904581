import Inventory from "./Inventory";
import { utils } from "./utils";
import GameObject from "./GameObject";

export default class InventoryMenu extends Inventory {
    constructor(config) {
        super(config);

        // Статичная картинка меню инвентаря, как он снизу там выглядит
        this.src = `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/inventoryMenu-background.${process.env.REACT_APP_BIF}`;
        this.inventoryImage = new Image();
        this.inventoryImage.src = this.src;

        // Какую коллекцию открываем
        this.inventoryCollection = config.inventoryCollection || null;
        this.map = config.map; // Забираем карту, чтобы знать, куда возвращаться

        // На каком объекте находимся
        this.activeElement = 0;
    }

    createElement() {

        let transformFactor = utils.getTransformFactor();
        let scaleFactor = utils.getScaleFactor();

        this.element = document.createElement("div");
        this.element.classList.add("InventoryMenu");

        //
        // Загрузили фоновую картинку
        let backgroundImageElement = document.createElement("img");
        backgroundImageElement.setAttribute("src", this.src );
        backgroundImageElement.style.transform = `scale(${scaleFactor})`;
        backgroundImageElement.classList.add("InventoryMenu");
        backgroundImageElement.style.left = `${324 * transformFactor}px`;
        backgroundImageElement.style.top = `${43 * transformFactor}px`;

        // Для удобства сохраняем тип объекта
        let collectableType = this.inventoryCollection.collectableType;
        this.collectableType = collectableType;

        // Загружаем объекты и сортируем их по порядку
        let gameObjects = this.inventoryCollection.gameObjects;
        let gameObjectsOrdered = []

        for (var key in gameObjects) {
            gameObjectsOrdered.push([key, gameObjects[key]['order']]);
        }
        
        gameObjectsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });

        this.gameObjectsOrdered = gameObjectsOrdered;

        //
        // Создаем отображение текста
        let newTextElement = document.createElement("p");
        newTextElement.classList.add(`gameObjectName`);
        newTextElement.textContent = `${collectableType} ${this.activeElement + 1}/${gameObjectsOrdered.length}`;

        newTextElement.style.position = `absolute`;
        newTextElement.style.left = `${387*transformFactor}px`;
        newTextElement.style.top = `${87*transformFactor}px`;
        newTextElement.style.height = `${100*transformFactor}px`;
        newTextElement.style.width = `${1237*transformFactor}px`;
        newTextElement.style['font-size'] = `${100*transformFactor}px`;
        newTextElement.style['color'] = `#35180D`;
        newTextElement.style['background-color'] = `#89694D`;

        newTextElement.style['text-align'] = 'left';
        newTextElement.style['vertical-align'] = 'middle';
        newTextElement.style['line-height'] = `${100*transformFactor}px`;

        newTextElement.style['margin-top'] = `0px`;
        newTextElement.style['margin-bottom'] = `0px`;

        // Отображаем объект с текущим индексом
        let displayedGameObject = gameObjects[gameObjectsOrdered[this.activeElement][0]];
        let objectImageElement = document.createElement("img");
        objectImageElement.classList.add(`inventoryMenuImage`);

        objectImageElement.setAttribute("src", `${process.env.REACT_APP_API_URL}pandaQuest/images/inventory/${displayedGameObject.name}-inventory-opened.${process.env.REACT_APP_GOF}`);
        objectImageElement.style.position = `absolute`;

        objectImageElement.style.left = `${602*transformFactor}px`;
        objectImageElement.style.top = `${263*transformFactor}px`;
        objectImageElement.style.transform = `scale(${scaleFactor})`;

        this.element.appendChild(backgroundImageElement);
        this.element.appendChild(newTextElement);
        this.element.appendChild(objectImageElement);

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor();
            let scaleFactor = utils.getScaleFactor();
            
            backgroundImageElement.style.transform = `scale(${scaleFactor})`
            backgroundImageElement.style.left = `${324 * transformFactor}px`;
            backgroundImageElement.style.top = `${43 * transformFactor}px`;

            newTextElement.style.left = `${387*transformFactor}px`;
            newTextElement.style.top = `${87*transformFactor}px`;
            newTextElement.style.height = `${100*transformFactor}px`;
            newTextElement.style.width = `${1237*transformFactor}px`;
            newTextElement.style['font-size'] = `${100*transformFactor}px`;
            newTextElement.style['line-height'] = `${100*transformFactor}px`;

            newTextElement.style['margin-top'] = `0px`;
            newTextElement.style['margin-bottom'] = `0px`;

            objectImageElement.style.left = `${602*transformFactor}px`;
            objectImageElement.style.top = `${263*transformFactor}px`;
            objectImageElement.style.transform = `scale(${scaleFactor})`;
        });

    };

    increaseImage() {
        if (this.activeElement + 1 < this.gameObjectsOrdered.length) {
            this.activeElement += 1;
        } else {
            this.activeElement = 0;
        }
        
        // Меняем текст
        let gameObjectName = this.element.querySelectorAll('.gameObjectName')[0];
        gameObjectName.textContent = `${this.collectableType} ${this.activeElement + 1}/${this.gameObjectsOrdered.length}`;

        // Меняем картинку
        let gameObjectImage = this.element.querySelectorAll('.inventoryMenuImage')[0];

        let gameObjects = this.inventoryCollection.gameObjects;
        let displayedGameObject = gameObjects[this.gameObjectsOrdered[this.activeElement][0]];

        gameObjectImage.setAttribute("src", `${process.env.REACT_APP_API_URL}pandaQuest/images/inventory/${displayedGameObject.name}-inventory-opened.${process.env.REACT_APP_GOF}`);
    }

    decreaseImage() {
        if (this.activeElement === 0) {
            this.activeElement = this.gameObjectsOrdered.length - 1;
        } else {
            this.activeElement -= 1;
        }
        
        // Меняем текст
        let gameObjectName = this.element.querySelectorAll('.gameObjectName')[0];
        gameObjectName.textContent = `${this.collectableType} ${this.activeElement + 1}/${this.gameObjectsOrdered.length}`;

        // Меняем картинку
        let gameObjectImage = this.element.querySelectorAll('.inventoryMenuImage')[0];

        let gameObjects = this.inventoryCollection.gameObjects;
        let displayedGameObject = gameObjects[this.gameObjectsOrdered[this.activeElement][0]];
        
        gameObjectImage.setAttribute("src", `${process.env.REACT_APP_API_URL}pandaQuest/images/inventory/${displayedGameObject.name}-inventory-opened.${process.env.REACT_APP_GOF}`);
    }

    addButtonElements() {

        let arrowLeftButton = new GameObject({
            name: "inventoryMenu-arrow-left",
            onClickEvents: [{type:'decreaseCodeImage', 
                            inventoryMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-left.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-left-glow.${process.env.REACT_APP_GOF}`,
            x: 376,
            y: 1035,
        })
        arrowLeftButton.init({map: this.map,
            container:this.element})

        let arrowRightButton = new GameObject({
            name: "inventoryMenu-arrow-right",
            onClickEvents: [{type:'increaseCodeImage', 
                            inventoryMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-right.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-right-glow.${process.env.REACT_APP_GOF}`,
            x: 3239,
            y: 1035,
        })
        arrowRightButton.init({map: this.map,
            container:this.element})

        let arrowBackButton = new GameObject({
            name: "inventoryMenu-arrow-back",
            onClickEvents: [{type:'changeMap', map: this.map.name}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-back.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/inventoryMenu-arrow-back-glow.${process.env.REACT_APP_GOF}`,
            isRemovable: false,
            x: 3365,
            y: 86,
        })
        arrowBackButton.init({map: this.map,
            container:this.element})
            
    };

    init(container) {
        this.createElement();
        container.appendChild(this.element);
        this.addButtonElements();
    }
}