import React, {useContext, useEffect} from 'react';
import "../styles/global.css"
import "../styles/mapStyles.css"
import Quest from '../gamefiles/Quest';
import { Context } from '..';
import { getCode } from '../http/userAPI';
import {observer} from "mobx-react-lite";
import { useHistory} from "react-router-dom";
import { LOGIN_ROUTE } from '../utils/consts';
import { getQuestEvents } from '../http/questStateAPI';
import GameObject from '../gamefiles/GameObject';
// import MetaTags from 'react-meta-tags';
  
const Game = observer(() => {

  const {user} = useContext(Context)
  const history = useHistory()

  const urlParams = new URLSearchParams(window.location.search);
  const uuid = urlParams.get('uuid')

  const getUserParams = async () => {

    try {
        // let data;
        let {name, code} = await getCode(uuid)
        if ((!name)|(!code)) {
          localStorage.setItem('token', '')
          history.push(`${LOGIN_ROUTE}`)
        }
        ////////////////////////////////////////////////////////
        if (user.isAuth && name && code) {
          // Создаем подключение
          console.log('CODE', code, 'NAME', name)
          const socket = new WebSocket(`wss://api.drunkpandagames.ru/`);
          user.setSocket(socket)

          socket.onopen = () => {
              console.log('Подключение установлено')
              socket.send(JSON.stringify({
                  id:code,
                  username: name,
                  method: "connection"
              }))
          }

          let quest = new Quest({element : document.querySelector(".game-container")})
          await quest.init()

          socket.id = uuid;

          quest.socket = socket;
          quest.code = code;

          // // Прогружаем все ивенты
          let qe = await getQuestEvents(quest.code)
          let qe_reorg = []

          qe.forEach(e => {
            qe_reorg.push(JSON.parse(e.payload))
          });

          // Преобразуем все игровые объекты в тип GameObject
          qe_reorg.forEach(event => {
              if (event['gameObjects'] && ['addGameObjects'].includes(event.type)) {
                  Object.keys(event['gameObjects']).forEach(gameObj => {
                    event['gameObjects'][gameObj] = new GameObject(event['gameObjects'][gameObj])
                  })
              }
          })

          // console.log(qe_reorg)

          quest.executeEvents(qe_reorg)

          quest.socket.onmessage = (event) => {
              let msg = JSON.parse(event.data)
              switch (msg.method) {
                  case "connection":
                      console.log(`пользователь ${msg.username} присоединился`)
                      break
                  case "questEvent":
                      questEventHandler(msg, quest)
                      // console.log(event)
                      break
              }
          }
          }

          const questEventHandler = (msg, quest) => {
            const qe = msg.event
            quest.executeEvents([qe])
        }
          ////////////////////////////////////////////////////////
    } catch (e) {
        // Алерт для пользователя, если проскочила какая-то ошибка
        alert(e)
        localStorage.setItem('token', '')
        history.push(`${LOGIN_ROUTE}`)
    }   
}

getUserParams()
{/* <meta id="meta-description" name="description" content="Some description." /> */}
{/* <meta id="og-title" property="og:title" content="MyApp" /> */}
{/* <meta id="og-image" property="og:image" content="path/to/image.jpg" /> */}
  return (
    <div className="wrapper">
      <meta httpEquiv="Cache-Control" content="max-age=7200" />
      <div className='game-container'></div>
    </div> 
  );
}
)

export default Game;
