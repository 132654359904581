export const utils = {

    wait(ms) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, ms)
        })
    },

    getTransformFactor() {
        // То как преобразовывать координаты. Если в фигме было 3840, то 3840
        let cw = document.querySelector(".game-container").clientWidth;
        let transformFactor = cw/3840;
        return transformFactor
    },

    getScaleFactor() {
      // То как преобразовывать картинки. Если мы отскейлили 3840 на 50%, то 1920
      let cw = document.querySelector(".game-container").clientWidth;
      let transformFactor = cw/1920;
      return transformFactor
  }
}