import InventoryObject from "./InventoryObject";
import InventoryCollection from "./InventoryCollection";
import { utils } from "./utils";

export default class Inventory {
    constructor(config) {
        // Коллекции объектов
        this.inventoryCollections = config.inventoryCollections || [];

        // Статичная картинка инвентаря, как он снизу там выглядит
        this.src = `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/inventory-background.${process.env.REACT_APP_BIF}`;
        this.inventoryImage = new Image();
        this.inventoryImage.src = this.src;

        // На какое место встанет следующий объект
        this.freeSpace = this.inventoryCollections.length;

        // Привязка к квесту
        this.quest = config.quest || null;
    }

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("Inventory");
        this.element.style.backgroundImage = `url('${this.src}')`;
    };

    addObject(gameObject) {
        // Создаем новый объект, используя характеристики старого
        // Он должен быть привязан к инвентарю
        let inventoryObject = new InventoryObject({
            name: gameObject.name,
            collectableType: gameObject.collectableType,
            isInventory: true,
            x: 132 + 457 * this.freeSpace,
            y: 37,
            inventory: this,
            weight: gameObject.weight
        })

        let isNew = true;
        let collectionIndex = 0;

        // console.log(this.inventoryCollections)

        for (let i=0; i < this.inventoryCollections.length; i++) {
            if (this.inventoryCollections[i].collectableType === inventoryObject.collectableType) {
                isNew = false;
                collectionIndex = i;
            }
        }

        // console.log(collectionIndex)

        // console.log('FREESPACE', this.freeSpace)

        if (isNew) {
            // Создаем новую коллекцию
            let inventoryCollection = new InventoryCollection({
                // Имя используется для src
                name: inventoryObject.name,

                // collectableType используется для определения класса
                collectableType: inventoryObject.collectableType,
                isInventory: true,

                // Расстояние между клеточками хардами
                // Но стоит потом вынести в характеристики инвентаря
                x: 132 + 457 * this.freeSpace, 
                y: 37,
                inventory: this,
                gameObjects: {}
            })

            inventoryObject.order = 0;
            // Добавляем в нее новый объект
            inventoryCollection.addObject(inventoryObject);

            let inventoryElement = document.getElementsByClassName(`Inventory`)[0];

            // Отображаем коллекцию на экране
            // inventoryCollection.init(this.element);
            inventoryCollection.init(inventoryElement);

            // Обновляем инвентарь
            this.inventoryCollections.push(inventoryCollection);
            this.freeSpace = this.inventoryCollections.length;
        } else {
            // Добавляем в коллекцию новый объект
            inventoryObject.order = Object.keys(this.inventoryCollections[collectionIndex].gameObjects).length;
            this.inventoryCollections[collectionIndex].addObject(inventoryObject);
        }
        let cType = inventoryObject.collectableType;

        // Создаем активный счетчик
        let newTextElement = document.createElement("p");
        newTextElement.classList.add(`objectCounterText-${cType}`);

        let transformFactor = utils.getTransformFactor();

        if (isNew) {
            newTextElement.textContent = 1;
            newTextElement.style.left = `${(126 + 320)*transformFactor + 454 * transformFactor * (this.freeSpace - 1)}px`;
        }
        else {
            newTextElement.textContent = Object.keys(this.inventoryCollections[collectionIndex].gameObjects).length;
            newTextElement.style.left = `${(126 + 320)*transformFactor + 454 * transformFactor * collectionIndex}px`;
        }

        

        // Стиль закодим хардами, можно потом вынести в характеристики инвентаря
        newTextElement.style.position = `absolute`;
        // newTextElement.style.left = `${(126 + 320)*transformFactor + 454 * transformFactor * collectionIndex}px`;
        newTextElement.style.top = `${45*transformFactor}px`;

        newTextElement.style.height = `${80*transformFactor}px`;
        newTextElement.style.width = `${80*transformFactor}px`;
        newTextElement.style['background-color'] = '#ffffff';
        newTextElement.style['font-size'] = `${80*transformFactor}px`;

        newTextElement.style['text-align'] = 'center';
        newTextElement.style['vertical-align'] = 'middle';
        newTextElement.style['line-height'] = `${80*transformFactor}px`;

        newTextElement.style['margin-top'] = `0px`;
        newTextElement.style['margin-bottom'] = `0px`;

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor();
            // let counter = 0;
            let previousTextElements = document.querySelectorAll(`p`);

            for (let i=0; i<previousTextElements.length; i++) {
                if (previousTextElements[i].getAttribute('class').includes(`objectCounterText`)) {
                    let collectionIndex = 0;

                    
                    for (let j=0; j < this.inventoryCollections.length; j++) {
                        if (previousTextElements[i].getAttribute('class').includes(this.inventoryCollections[j].collectableType)) {
                            collectionIndex = j;
                            // console.log(previousTextElements[i].getAttribute('class'), this.inventoryCollections[j].collectableType)
                        }
                    }

                    // console.log(this.inventoryCollections[i].collectableType, collectionIndex)

                    previousTextElements[i].style.left = `${(126 + 320)*transformFactor + 454 * transformFactor * collectionIndex}px`;
                    previousTextElements[i].style.top = `${45*transformFactor}px`;

                    previousTextElements[i].style.height = `${80*transformFactor}px`;
                    previousTextElements[i].style.width = `${80*transformFactor}px`;
                    previousTextElements[i].style['background-color'] = '#ffffff';
                    previousTextElements[i].style['font-size'] = `${80*transformFactor}px`;

                    previousTextElements[i].style['text-align'] = 'center';
                    previousTextElements[i].style['vertical-align'] = 'middle';
                    previousTextElements[i].style['line-height'] = `${80*transformFactor}px`;

                    previousTextElements[i].style['margin-top'] = `0px`;
                    previousTextElements[i].style['margin-bottom'] = `0px`;

                    // counter += 1;
                };
            }
            
        });

        let previousTextElements = document.querySelectorAll(`p`);
        for (let i=0; i<previousTextElements.length; i++) {
            if (previousTextElements[i].getAttribute('class') === `objectCounterText-${cType}`) {
                previousTextElements[i].remove();
            };
        }

        this.element.appendChild(newTextElement);

        // console.log('INVENTORY_COLLECTIONS', this.inventoryCollections)
    }

    removeInventoryCollection(collectableType) {
        // console.log('reached inventory')
        let removeIndex = -1;
        for (let i=0; i<this.inventoryCollections.length; i++) {
            if (this.inventoryCollections[i].collectableType === collectableType) {
                removeIndex = i;
            }
        }

        // console.log(removeIndex);

        let transformFactor = utils.getTransformFactor();

        if (removeIndex > -1) {
            // Удаляем соответствующий элемент
            this.inventoryCollections[removeIndex].gameObjectElement.remove();
            
            // Сдвигаем все остальные изображения
            for (let j=removeIndex+1; j < this.inventoryCollections.length; j++) {
                this.inventoryCollections[j].x -= (126 + 320) //*transformFactor;
                this.inventoryCollections[j].gameObjectElement.remove();
                this.inventoryCollections[j].init(this.element);
            }

            // Удаляем текст счетчика удаляемого класса
            let previousTextElements = document.querySelectorAll(`p`);
            for (let i=0; i<previousTextElements.length; i++) {
                if (previousTextElements[i].getAttribute('class') === `objectCounterText-${collectableType}`) {
                    previousTextElements[i].remove();
                } else {
                    // И двигаем все текстовые элементы
                    for (let j=removeIndex+1; j < this.inventoryCollections.length; j++) {
                        let cType = this.inventoryCollections[j].collectableType;
                        
                        if (previousTextElements[i].getAttribute('class').split("-")[1] === cType) {
                            let element = previousTextElements[i];
                            // console.log(parseFloat(element.style.left.substring(0, element.style.left.length-2)));
                            let newLeft = (parseFloat(element.style.left.substring(0, element.style.left.length-2))-457*transformFactor)
                            element.style.left = `${newLeft}px`;
                        }
                        
                    }
                }
            }

            this.inventoryCollections.splice(removeIndex, 1);
        }

        this.freeSpace = this.inventoryCollections.length;
        
    }

    init(container) {

        // Отрисовываем инвентарь и добавляем его на страницу
        this.createElement();
        container.appendChild(this.element);

        // Добавить метод на отрисовку добавленных элементов
    }
}