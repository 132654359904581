import CodePuzzle from "./CodePuzzle";
import GameObject from "./GameObject";

export default class CodePuzzleImages extends CodePuzzle {
    constructor(config) {
        super(config);

        this.codeImages = config.codeImages || [
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage0.${process.env.REACT_APP_GOF}`,
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage1.${process.env.REACT_APP_GOF}`,
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage2.${process.env.REACT_APP_GOF}`,
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage3.${process.env.REACT_APP_GOF}`,
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage4.${process.env.REACT_APP_GOF}`,
            `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/pandaTempleDay_CodeLock-codeImage5.${process.env.REACT_APP_GOF}`,
            ]
    }

    createElement() {
        this.element = document.createElement("div");
        this.element.classList.add("QuestMap");
        this.element.style.backgroundImage = `url('${this.src}')`;

        // Создаем отображение блоков c кодом
        for (let i=0; i<this.correctCode.length; i++) {

            let codePuzzleImage = new GameObject({
                name: `codePuzzleImage-${i}`,
                nonClickable: true,
                map: this,
                src: this.codeImages[parseInt(this.currentCode[i])],
                x: this.x + 
                i * (this.width + this.space),
                y: this.yBlock
            })
            codePuzzleImage.init({map: this,
                container:this.element})

        };

    };

    addButtonElements() {
        for (let i=0; i<this.correctCode.length; i++) {
            let arrowUpButton = new GameObject({
                onClickEvents: [{type:'increaseCodeImage', 
                                index: i, 
                                images: this.codeImages, 
                                possibleValues: this.possibleValues,
                                map: this}],
                map: this,
                src: this.upArrowSrc,
                src_glow: this.upArrowSrcGlow,
                x: this.xArrows + i * (this.width + this.space),
                y: this.yArrowUp,
            })
            arrowUpButton.init({map: this,
                container:this.element})

            let arrowDownButton = new GameObject({
                onClickEvents: [{type:'decreaseCodeImage', 
                                index: i, 
                                images: this.codeImages, 
                                possibleValues: this.possibleValues,
                                map: this}],
                map: this,
                src: this.downArrowSrc,
                src_glow: this.downArrowSrcGlow,
                x: this.xArrows + i * (this.width + this.space),
                y: this.yArrowDown,
            })
            arrowDownButton.init({map: this,
                container:this.element})
        }
    };

    init(container) {
        this.createElement();
        container.insertBefore(this.element, container.firstChild);
        this.mountObjects();
        this.addNavigationElements();
        this.addButtonElements();
    };
}