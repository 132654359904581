import InventoryMenu from "./InventoryMenu";
import { utils } from "./utils";
import GameObject from "./GameObject";

export default class HintsMenu extends InventoryMenu {
    constructor(config) {
        super(config);

        // Статичная картинка меню инвентаря, как он снизу там выглядит
        this.src = `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/hintsMenu.${process.env.REACT_APP_BIF}`;
        this.buttonSrc = `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/hintsMenuButton.${process.env.REACT_APP_GOF}`;

        this.map = config.map; // Забираем карту, чтобы знать, куда возвращаться
        if (this.map.quest.hintsAsked > 0) {
            this.activeElement = 1; // Изначально никакие подсказки не отображаются
        } else {
            this.activeElement = 0;
        }        
    }

    createElement() {


        let transformFactor = utils.getTransformFactor();
        let scaleFactor = utils.getScaleFactor();

        this.element = document.createElement("div");
        this.element.classList.add("HintsMenu");

        //
        // Загрузили фоновую картинку
        let backgroundImageElement = document.createElement("img");
        backgroundImageElement.setAttribute("src", this.src );
        backgroundImageElement.style.transform = `scale(${scaleFactor})`;
        backgroundImageElement.classList.add("HintsMenu");
        // backgroundImageElement.style['transform-orifin'] = 'top left'

        backgroundImageElement.style.left = `${823 * transformFactor}px`;
        backgroundImageElement.style.top = `${535 * transformFactor}px`;

        // Добавляем заголовок с номером подсказки
        let hintNumber = 0;
        let totalHints = 0;

        if (this.map.quest.hintsAsked > 0) {
            // Если уже спрашивали подсказку, начинаем с первой подсказки
            // Иначе - показываем, что их ноль
            totalHints = this.map.quest.hintsAsked;
            hintNumber = totalHints;
            this.activeElement = totalHints;
        }

        console.log('Info about HintsMenu', this, totalHints, this.map.quest)

        let questStage = this.map.quest.stage;
        let relevantHints = this.map.quest.hintsStructure[questStage]['hints']

        let newTextElement = document.createElement("p");
        newTextElement.classList.add(`hintsHeader`);
        newTextElement.textContent = `Подсказка ${totalHints} / ${totalHints}`;

        newTextElement.style.position = `absolute`;
        newTextElement.style.left = `${1129*transformFactor}px`;
        newTextElement.style.top = `${708*transformFactor}px`;
        newTextElement.style.height = `${124*transformFactor}px`;
        newTextElement.style.width = `${1586*transformFactor}px`;
        newTextElement.style['font-size'] = `${124*transformFactor}px`;
        newTextElement.style['color'] = `#35180D`;

        newTextElement.style['text-align'] = 'center';
        newTextElement.style['vertical-align'] = 'middle';
        newTextElement.style['line-height'] = `${124*transformFactor}px`;

        newTextElement.style['margin-top'] = `0px`;
        newTextElement.style['margin-bottom'] = `0px`;

        // Добавляем заголовок с номером подсказки
        let newTextElement2 = document.createElement("p");
        newTextElement2.classList.add(`hintsBody`);

        if (hintNumber === 0) {
            newTextElement2.textContent = `Вы еще не брали подсказок на данном этапе квеста.`;
        } else {
            newTextElement2.textContent = relevantHints[hintNumber-1];
        }

        newTextElement2.style.position = `absolute`;
        newTextElement2.style.left = `${1129*transformFactor}px`;
        newTextElement2.style.top = `${870*transformFactor}px`;
        newTextElement2.style.height = `${651*transformFactor}px`;
        newTextElement2.style.width = `${1586*transformFactor}px`;
        newTextElement2.style['font-size'] = `${110*transformFactor}px`;
        newTextElement2.style['color'] = `#35180D`;

        newTextElement2.style['text-align'] = 'left';
        newTextElement2.style['vertical-align'] = 'middle';
        newTextElement2.style['line-height'] = `${110*transformFactor}px`;

        newTextElement2.style['margin-top'] = `0px`;
        newTextElement2.style['margin-bottom'] = `0px`;

        window.addEventListener('resize', () =>{
            let transformFactor = utils.getTransformFactor();
            let scaleFactor = utils.getScaleFactor();

            backgroundImageElement.style.transform = `scale(${scaleFactor})`
            backgroundImageElement.style.left = `${823 * transformFactor}px`;
            backgroundImageElement.style.top = `${535 * transformFactor}px`;

            //////
            
            newTextElement.style.position = `absolute`;
            newTextElement.style.left = `${1129*transformFactor}px`;
            newTextElement.style.top = `${708*transformFactor}px`;
            newTextElement.style.height = `${124*transformFactor}px`;
            newTextElement.style.width = `${1586*transformFactor}px`;
            newTextElement.style['font-size'] = `${124*transformFactor}px`;
            newTextElement.style['color'] = `#35180D`;

            newTextElement.style['text-align'] = 'center';
            newTextElement.style['vertical-align'] = 'middle';
            newTextElement.style['line-height'] = `${124*transformFactor}px`;

            newTextElement.style['margin-top'] = `0px`;
            newTextElement.style['margin-bottom'] = `0px`;

            //////

            newTextElement2.style.position = `absolute`;
            newTextElement2.style.left = `${1129*transformFactor}px`;
            newTextElement2.style.top = `${870*transformFactor}px`;
            newTextElement2.style.height = `${651*transformFactor}px`;
            newTextElement2.style.width = `${1586*transformFactor}px`;
            newTextElement2.style['font-size'] = `${110*transformFactor}px`;
            newTextElement2.style['color'] = `#35180D`;

            newTextElement2.style['text-align'] = 'left';
            newTextElement2.style['vertical-align'] = 'middle';
            newTextElement2.style['line-height'] = `${110*transformFactor}px`;

            newTextElement2.style['margin-top'] = `0px`;
            newTextElement2.style['margin-bottom'] = `0px`;
        });

        this.element.appendChild(backgroundImageElement);
        this.element.appendChild(newTextElement);
        this.element.appendChild(newTextElement2);
        
    };

    addButtonElements() {

        let arrowLeftButton = new GameObject({
            name: "hintsMenu-arrow-left",
            onClickEvents: [{type:'decreaseHint', hintsMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-left.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-left-glow.${process.env.REACT_APP_GOF}`,
            x: 823,
            y: 1054,
        })
        arrowLeftButton.init({map: this.map,
            container:this.element})

        let arrowRightButton = new GameObject({
            name: "hintsMenu-arrow-right",
            onClickEvents: [{type:'increaseHint', hintsMenu: this}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-right.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-right-glow.${process.env.REACT_APP_GOF}`,
            x: 2791,
            y: 1054,
        })
        arrowRightButton.init({map: this.map,
            container:this.element})

        let arrowBackButton = new GameObject({
            name: "hintsMenu-arrow-back",
            onClickEvents: [{type:'changeMap', map: this.map.name}],
            map: this.map,
            src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-back.${process.env.REACT_APP_GOF}`,
            src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-arrow-back-glow.${process.env.REACT_APP_GOF}`,
            isRemovable: false,
            x: 2872,
            y: 599,
        })
        arrowBackButton.init({map: this.map,
            container:this.element})
        
        let getNewHintButton = new GameObject({
                name: "hintsMenu-getNewHintButton",
                // onClickEvents: [{type:'getNewHint', hintsMenu: this}],
                onClickEvents: [{type:'getNewHint'}],
                map: this.map,
                src: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-getNewHintButton.${process.env.REACT_APP_GOF}`,
                src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/hintsMenu-getNewHintButton-glow.${process.env.REACT_APP_GOF}`,
                x: 1591,
                y: 1568,
            })
        getNewHintButton.init({map: this.map,
            container:this.element})
    };

    increaseHint() {

        console.log('increase', this.map.quest)

        // Нас не интересует перескок
        if (this.map.quest.hintsAsked > 0) {

            console.log('ACTIVE ELEMENT', this.activeElement, 'HINTS ASKED', this.map.quest.hintsAsked)

            if (this.activeElement < this.map.quest.hintsAsked) {
                this.activeElement += 1;
            }
            
            // Меняем текст
            let questStage = this.map.quest.stage;

            let relevantHints = this.map.quest.hintsStructure[questStage]['hints']
            let hintsBody = this.element.querySelectorAll('.hintsBody')[0];
            hintsBody.textContent = `${relevantHints[this.activeElement-1]}`;

            let hintsHeader = this.element.querySelectorAll('.hintsHeader')[0];
            hintsHeader.textContent = `Подсказка ${this.activeElement} / ${this.map.quest.hintsAsked}`;
        }
    }

    decreaseHint() {

        console.log('decrease', this.map.quest)

        if (this.map.quest.hintsAsked > 0) {

            if (this.activeElement > 1) {
                this.activeElement -= 1;

                // Меняем текст
                let questStage = this.map.quest.stage;

                let relevantHints = this.map.quest.hintsStructure[questStage]['hints']
                let hintsBody = this.element.querySelectorAll('.hintsBody')[0];
                hintsBody.textContent = `${relevantHints[this.activeElement-1]}`;

                let hintsHeader = this.element.querySelectorAll('.hintsHeader')[0];
                hintsHeader.textContent = `Подсказка ${this.activeElement} / ${this.map.quest.hintsAsked}`;
            }
            
        }
    }

    init(container) {
        this.createElement();
        container.appendChild(this.element);
        this.addButtonElements();
    }
}