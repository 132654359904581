// Это API позволяет доставать изменяемые параметры - произошедшие события и redirect

import {$host} from "./index";

export const getRedirectMaps = async (code) => {
    try {
        const {data} = await $host.get(`api/questState/getRedirectMaps/${code}`)
        console.log('GOT REDIRECT MAPS', data)

        let redirectMapsDict = {}

        data.forEach(e => {
            let local_dict = JSON.parse(e.payload)
            Object.keys(local_dict).forEach(key => {
                redirectMapsDict[key] = local_dict[key]
            })
        });

        return redirectMapsDict
        // return JSON.parse(data)
    } catch (e) {
        console.log(e)
    }
}

export const getQuestEvents = async (code) => {
    try {
        const {data} = await $host.get(`api/questState/getQuestEvents/${code}`)
        // return JSON.parse(data)
        return data
    } catch (e) {
        console.log(e)
    }
}

// export const setQuestEvents = async (code, qe) => {
//     try {
//         const questEvents = JSON.stringify(qe)
//         const {data} = await $host.post(`api/questState/setQuestEvents/${code}`, {questEvents})
//         return data
//     } catch (e) {
//         console.log(e)
//     }
// }

export const addQuestEvent = async (code, qe) => {
    try {
        const questEvents = JSON.stringify(qe)
        const {data} = await $host.post(`api/questState/addQuestEvent/${code}`, {questEvents})
        return data
    } catch (e) {
        console.log(e)
    }
}

// export const setRedirectMaps = async (code, rm) => {
//     try {
//         const redirectMaps = JSON.stringify(rm)
//         const {data} = await $host.post(`api/questState/setRedirectMaps/${code}`, {redirectMaps})
//         return data
//     } catch (e) {
//         console.log(e)
//     }
// }

export const addRedirectMap = async (code, rm) => {
    try {
        // const redirectMap = JSON.stringify(rm)
        const redirectMap = rm;
        const {data} = await $host.post(`api/questState/addRedirectMap/${code}`, {redirectMap})
        return data
    } catch (e) {
        console.log(e)
    }
}