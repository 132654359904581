import React, {useContext, useEffect, useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import {observer} from "mobx-react-lite";
import { Context } from '.';
import { check } from './http/userAPI';
import {Spinner} from "react-bootstrap";
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const App = observer(() => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  // const params = useParams()
  
  useEffect(() => {
    check().then(data => {
      user.setUser(true)
      user.setIsAuth(true)
    }).finally(() => setLoading(false))
  }, [])

  console.log(user, user.isAuth, user.name)

  if (loading) {
    return <Spinner animation="grow"/>
  }

  return (
      <BrowserRouter>
          <AppRouter />
      </BrowserRouter>
  );
});

export default App;

