import GameObject from "./GameObject";
import { utils } from "./utils";
import KeyPressListener from "./KeyPressListener";

export default class NavigationButton extends GameObject {
    constructor(config) {

        super(config);

        this.map = {
            "ArrowLeft":"left",
            "KeyA": 'left',

            "ArrowRight":"right",
            "KeyD": 'right',
        }

        this.direction = config.direction;

        this.src = config.src || `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/arrow-${this.direction}.${process.env.REACT_APP_GOF}`;
        this.src_glow = config.src_glow || `${process.env.REACT_APP_API_URL}pandaQuest/images/navigation/arrow-${this.direction}-glowing.${process.env.REACT_APP_GOF}`;
        this.x = config.x;
        this.y = config.y;

        // Экономим время на постоянное задание координат, делаем их гибкими
        this.loadButtonParameters();
    };
    
    loadButtonParameters() {
        if (this.x) {

        } else {
            switch(this.direction) {
                case 'left':
                    this.x = 29;
                    this.y = 1067;
                    break;
                case 'right':
                    this.x = 3691;
                    this.y = 1067;
                    break;
                case 'back':
                    this.x = 10;
                    this.y = 1900;
                    break;
                case 'default':
                    this.x = 153;
                    this.y = 162;
            }
        }
    }

    createElement(map) {
        this.gameObjectElement = document.createElement("img");
        this.gameObjectElement.classList.add("GameObject");
        this.gameObjectElement.classList.add("NavigationButton");
        this.gameObjectElement.setAttribute("src", this.src );
        
        let transformFactor = utils.getTransformFactor();
        let scaleFactor = utils.getScaleFactor();

        this.gameObjectElement.style.left = `${this.x*transformFactor}px`;
        this.gameObjectElement.style.top = `${this.y*transformFactor}px`; 
        this.gameObjectElement.style.transform = `scale(${scaleFactor})`

        this.map = map;

        switch(this.direction) {
            case 'left':
                this.actionListener = new KeyPressListener("ArrowLeft", () => {
                    this.actionListener.unbind();
                    this.map.quest.executeEvents(this.onClickEvents);
                    })

                // this.actionListener = new KeyPressListener("KeyA", () => {
                //     this.actionListener.unbind();
                //     this.map.quest.executeEvents(this.onClickEvents);
                //     })
                break;
            case 'right':
                this.actionListener = new KeyPressListener("ArrowRight", () => {
                    this.actionListener.unbind();
                    this.map.quest.executeEvents(this.onClickEvents);
                    })

                // this.actionListener = new KeyPressListener("KeyD", () => {
                //     this.actionListener.unbind();
                //     this.map.quest.executeEvents(this.onClickEvents);
                //     })
                break;
            case 'back':
                this.actionListener = new KeyPressListener("Escape", () => {
                    this.actionListener.unbind();
                    this.map.quest.executeEvents(this.onClickEvents);
                    })
                break;
            case 'default':
        }
        
    }

}