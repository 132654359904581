import GameObject from "./GameObject";
import QuestMap from "./QuestMap";
import QuestEvent from "./QuestEvent";
import Inventory from "./Inventory";
import { getMapObjects, getNavigationStructure, getMapStructure, getHintsStructure, getImagesNames } from "../http/questMapAPI";
import { utils } from "./utils";
import Narration from "./Narration";

export default class Quest {
    constructor(config) {
        // Технические элементы
        this.element = config.element;
        this.socket = config.socket;

        // Создаем пустой инвентарь
        this.currentMap = null;
        this.inventory = null;

        this.mappings = [];

        // Добавляем информацию о подсказках
        // this.hintsStructure = window.questHints;
        this.stage = 0;
        this.hintsAsked = 0;

        this.soundVolume = 10;
    };

    async executeEvents(events) {
        
        for (let i = 0; i < events.length; i++) {
            // console.log('EXECUTING EVENT', events[i])
            
            const eventHandler = new QuestEvent({map: this.currentMap, event: events[i]});
            await eventHandler.init();
        }
    };

    async init() {

        // Вот эти параметры надо подгружать динамически
        this.currentMap = 'prehistory'
        this.currentQuest = window.location.pathname.split('/')[2]
        // this.code = 'ANNADEN'

        // На уровне квеста - для каждой карты все gameObjects переводим в класс GameObject
        this.mapStructure = await getMapStructure(this.currentQuest)
        this.imagesNames = await getImagesNames(this.currentQuest)
        let imagesNames = this.imagesNames;

        // Предзагрузка всех изображений
        let backgroundElement = document.createElement("div");
        backgroundElement.classList.add("loader");
        backgroundElement.style.position = 'absolute'
        backgroundElement.style.transformOrigin = 'top left'
        backgroundElement.style.top = '0px'
        backgroundElement.style.left = '0px'
        backgroundElement.style['z-index'] = 100
        backgroundElement.style.width = 3840 * utils.getTransformFactor();
        backgroundElement.style.height = 2640 * utils.getTransformFactor()

        let width = 3840 * utils.getTransformFactor()
        let height = 2640 * utils.getTransformFactor()

        backgroundElement.innerHTML = (`
                            <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
                                <rect x="${0}" y="${0}" width="${width}" height="${height}" fill="white" />
                                <rect class="fillContainer" x=${width/4} y=${height/2} height="${height*1/100}" fill="#82ff71" />
                                <rect x=${width/4} y=${height/2} width="50%" height="${height*1/100}" style="fill:transparent;stroke-width:${height*1/1000};stroke:black"/>
                            </svg>
                        `);

        document.querySelector(".game-container").appendChild(backgroundElement)

        let filled = 0
        this.imagesNames.forEach(imgName => {

            // console.log(imgName)
            var img = new Image();
            img.onload = function () {
                filled += 1;

                let elt = document.querySelector(".fillContainer")
                if (elt) {
                    elt.style.width = `${filled / imagesNames.length * 50}%`
                    // console.log(filled / imagesNames.length, filled, imagesNames.length)

                    if (filled / imagesNames.length >= 0.99) {
                        try {
                            document.querySelector(".loader").remove()
                        } catch {

                        }

                    }
                }
            }
            img.src = imgName;

        })

        // Для обычных gameObjects
        Object.keys(this.mapStructure).forEach(key_map => {
            if (this.mapStructure[key_map]['gameObjects']) {
                Object.keys(this.mapStructure[key_map]['gameObjects']).forEach(gameObj => {
                    this.mapStructure[key_map]['gameObjects'][gameObj] = new GameObject(this.mapStructure[key_map]['gameObjects'][gameObj])
                })
            }
        })

        // Для Jigsaw-объектов
        Object.keys(this.mapStructure).forEach(key_map => {
            if (this.mapStructure[key_map]['gameObjectsSpawn']) {
                Object.keys(this.mapStructure[key_map]['gameObjectsSpawn']).forEach(gameObj => {
                    this.mapStructure[key_map]['gameObjectsSpawn'][gameObj] = new GameObject(this.mapStructure[key_map]['gameObjectsSpawn'][gameObj])
                })
            }
        })


        this.mapObjects = await getMapObjects(this.currentMap, this.currentQuest)
        
        this.navigationStructure = await getNavigationStructure(this.currentQuest)
        this.hintsStructure = await getHintsStructure(this.currentQuest)

        if (this.mapStructure[this.currentMap].class == 'Narration') {
            this.currentMap = new Narration(this.mapStructure[this.currentMap]);
        } else {
            this.currentMap = new QuestMap(this.mapStructure[this.currentMap]);
        }
        
        this.currentMap.quest = this;
        this.currentMap.init(document.querySelector(".game-container"));

        // Отрисовываем инвентарь
        this.inventory = new Inventory({gameObjects : []});

        // console.log(this.mapObjects, this.navigationStructure)

        this.inventory.quest = this;
        this.inventory.init(document.querySelector(".game-container"));

    }
}
