// Это API позволяет доставать неизменяемые параметры - структуру квеста и навигации

import {$host} from "./index";

export const getMapObjects = async (currentMap, currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/mapObjects/${currentQuest}/${currentMap}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getNavigationStructure = async (currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/navigationStructure/${currentQuest}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getHintsStructure = async (currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/hintsStructure/${currentQuest}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getMapStructure = async (currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/mapStructure/${currentQuest}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getStateChangingLocations = async (currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/stateChangingLocations/${currentQuest}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getImagesNames = async (currentQuest) => {
    try {
        const {data} = await $host.get(`api/questMap/imagesNames/${currentQuest}`)
        return data
    } catch (e) {
        console.log(e)
    }
}