import QuestMap from "./QuestMap";
import CodePuzzle from "./CodePuzzle";
import JigsawPuzzle from "./JigsawPuzzle";
import Libra from "./Libra";
import Recorder from "./Recorder";
import MapPuzzle from "./MapPuzzle";
import CodePuzzleImages from "./CodePuzzleImages";
import MapMenu from "./MapMenu";
import GameObject from "./GameObject";
import InventoryMenu from "./InventoryMenu";
import HintsMenu from "./HintsMenu";
import { addQuestEvent, getRedirectMaps, addRedirectMap } from "../http/questStateAPI";
import { getStateChangingLocations } from "../http/questMapAPI";
import CodePuzzleImages2 from "./CodePuzzleImages2";
import InputPuzzle from "./InputPuzzle";
import MatchingPuzzle from "./MatchingPuzzle";
import Narration from "./Narration";
import { utils } from "./utils";
import {Howl} from 'howler';

export default class QuestEvent {
    constructor({map, event}) {
        this.map = map;
        this.event = event;
    };

    async changeMap(resolve) {

        if ((this.event.throwOut && (this.map.name === this.event.mapFrom))|(!this.event.throwOut)) {
            // Сначала проверяем редиректы по решенным загадкам
            let beforeRedirectMap = this.map.quest.mapStructure[this.event.map];
            let redirectMaps = await getRedirectMaps(this.map.quest.code);

            while (redirectMaps[beforeRedirectMap.name]) {
                let newName = redirectMaps[beforeRedirectMap.name];
                beforeRedirectMap = this.map.quest.mapStructure[newName];
            }

            let newMap = null;

            if (beforeRedirectMap.class === 'QuestMap') {
                newMap = new QuestMap(beforeRedirectMap);   
            } else if (beforeRedirectMap.class === 'CodePuzzle') {
                newMap = new CodePuzzle(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'JigsawPuzzle') {
                newMap = new JigsawPuzzle(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'Libra') {
                newMap = new Libra(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'Recorder') {
                newMap = new Recorder(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'MapPuzzle') {
                newMap = new MapPuzzle(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'CodePuzzleImages') {
                newMap = new CodePuzzleImages(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'CodePuzzleImages2') {
                newMap = new CodePuzzleImages2(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'MapMenu') {
                newMap = new MapMenu(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'InputPuzzle') {
                newMap = new InputPuzzle(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'MatchingPuzzle') {
                newMap = new MatchingPuzzle(beforeRedirectMap);
            } else if (beforeRedirectMap.class === 'Narration') {
                newMap = new Narration(beforeRedirectMap);
            }

            newMap.quest = this.map.quest;
            this.map.quest.currentMap = newMap;
            
            // Чистим карту, прежде чем подгружать новую
            // Иначе элементы рисуются один над другим, и портится производительность
            let activeQuestmaps = document.querySelectorAll(".QuestMap");
            for (let i=0; i<activeQuestmaps.length; i++) {
                activeQuestmaps[i].remove();
            }
            
            newMap.init(document.querySelector(".game-container"));

        }
        
        resolve();
    }

    increaseNarrative(resolve) {
        if (this.event.stageCounter === 0) {

            let transformFactor = utils.getTransformFactor();

            let backgroundElement = document.createElement("div");
            backgroundElement.classList.add("backgroundRectangle");
            backgroundElement.style.position = 'absolute'
            backgroundElement.style.transformOrigin = 'top left'
            backgroundElement.style.top = `${2160 * 0.05 * transformFactor}px`
            backgroundElement.style.left = `${3840 * 0.05 * transformFactor}px`
            backgroundElement.style.width = 3840 * 0.9 * transformFactor;
            backgroundElement.style.height = 2160 * 0.9 * transformFactor

            let width = 3840 * 0.9 * transformFactor
            let height = 2160 * 0.9 * transformFactor

            backgroundElement.innerHTML = (`
                                <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="${0}" y="${0}" width="${width}" height="${height}" fill="white" fill-opacity="0.7"/>
                                </svg>
                            `);
            
            document.querySelector(".QuestMap").appendChild(backgroundElement)

        }

        if (this.event.stageCounter > 0) {
            try {
                document.querySelector(".narration-text-displayed").remove()
            } catch {

            }
        }

        if (this.event.stageCounter < this.event.displayText.length) {

            let transformFactor = utils.getTransformFactor();
            let width = 3840 * 0.9 * transformFactor
            let height = 2160 * 0.9 * transformFactor
            
            let newTextElement = document.createElement("p");
            newTextElement.classList.add(`narration-text-displayed`);

            newTextElement.textContent = this.event.displayText[this.event.stageCounter];

            newTextElement.style.position = `absolute`;
            newTextElement.style.left = `${3840 * 0.1 * transformFactor}px`
            newTextElement.style.top = `${2160 * 0.1 * transformFactor}px`;

            newTextElement.style.height = `${height / 0.9 * 0.8}px`;
            newTextElement.style.width = `${width / 0.9 * 0.8}px`;
            // newTextElement.style['background-color'] = '#ffffff';
            newTextElement.style['font-size'] = `${100*transformFactor}px`;
            newTextElement.style['text-align'] = 'left';
            newTextElement.style['vertical-align'] = 'middle';
            newTextElement.style['line-height'] = `${150*transformFactor}px`;

            newTextElement.style['margin-top'] = `0px`;
            newTextElement.style['margin-bottom'] = `0px`;

            
            document.querySelector(".QuestMap").appendChild(newTextElement)

            this.map.stageCounter += 1
            this.map.reinitNavigationElements()
            // console.log(this.map)
        } else {
            this.map.quest.executeEvents(this.map.onSolveEvents)
        }
    }

    enableMap(resolve) {

        this.map.quest.mapEnabled = true;

        if (this.map.name === this.event.questMap) {
            this.map.quest.executeEvents([{type:'changeMap', map: this.event.redirectMap}])
        } else {
            this.map.quest.executeEvents([{type:'changeMap', map: this.map.name}])
        }
        
        resolve();
    }

    changeLevelPicture(resolve) {
        // Мы забираем квест с текущей карты
        // Изображение меняем на карте, указанной в ивенте
        let newSrc = this.event.newSrc;

        this.map.quest.mappings.forEach(mapping => {
            Object.keys(mapping).forEach(mappingKey => {
                newSrc = newSrc.replace(mappingKey, mapping[mappingKey])
            })
        })
        this.map.quest.mapStructure[this.event.map].src = newSrc;
        
        // Если вызвано на текущей карте, надо сразу поменять картинку сзади
        if (this.map.name === this.event.map) {
            document.querySelector(".QuestMap").style.backgroundImage = `url('${newSrc}')`;
        }
        resolve();
    }

    checkCode(resolve) {
        let codeEntered = document.getElementById("txtInputData").value;
        if (codeEntered === this.map.correctCode) {
            this.map.quest.executeEvents(this.map.onSolveEvents)
        } else {
            window.alert("Код некорректен");
        }

        resolve();
    }

    addInventory(resolve) {

        this.event.gameObjects.forEach(gameObj => {
            this.map.quest.inventory.addObject(gameObj);
        });
        resolve();
    }

    async recursiveStyleChange(resolve) {

        // Задаем последний маппинг из существующих как параметр квеста
        this.map.quest.mappings.push(this.event.mapping);

        let stateChangingLocations = await getStateChangingLocations(this.map.quest.currentQuest)

        // Для каждой локации, участвующей в смене стейта
        stateChangingLocations.forEach(loc => {
            // Генерируем новое имя картинки
            let newLevelPictureName = loc;
            
            if (Object.hasOwn(this.map.quest.mapStructure[loc], 'src')) {
                newLevelPictureName = this.map.quest.mapStructure[loc].src.split('?')[0].split('/').slice(-1)[0]
                newLevelPictureName = newLevelPictureName.replace(`.${process.env.REACT_APP_BIF}`, '')
            }

            this.map.quest.mappings.forEach(mapping => {
                Object.keys(mapping).forEach(mappingKey => {
                    newLevelPictureName = newLevelPictureName.replace(mappingKey, mapping[mappingKey])
                })
            })
            
            this.map.quest.executeEvents([{type:'changeLevelPicture', 
                                map: loc,
                                newSrc: `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/${newLevelPictureName}.${process.env.REACT_APP_BIF}`}])

            ;
            // console.log(this.map.quest.mapStructure[loc].gameObjects)
            if (this.map.quest.mapStructure[loc].gameObjects) {
                Object.keys(this.map.quest.mapStructure[loc].gameObjects).forEach(gameObj => {
                    // Сменить картинку для каждого gameObject'а
                    let pictureNameGameObj = newLevelPictureName.split('-')[0]
                    let gameObjectName = this.map.quest.mapStructure[loc].gameObjects[gameObj].name;
                    let newSrc = `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/${pictureNameGameObj}-${gameObjectName}.${process.env.REACT_APP_GOF}`;
                    let newSrcGlow = `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/${pictureNameGameObj}-${gameObjectName}-glow.${process.env.REACT_APP_GOF}`;

                    this.map.quest.mapStructure[loc].gameObjects[gameObj].changeObjectPicture(newSrc, newSrcGlow, this.map.quest, loc);

                })
            }

        })
        resolve();

    }
        
    playSound(resolve) {

        // Имитация нажатия на кнопку проигрывания
        if (this.event.button) {
            let prevSrc = this.map.gameObjects[this.event.button].gameObjectElement.src;
            prevSrc = prevSrc.replace('-glow', '');
            let newSrc = prevSrc.replace(this.event.button, this.event.changeSrc);

            console.log(prevSrc, newSrc);

            this.map.gameObjects[this.event.button].gameObjectElement.src = newSrc;
            this.map.gameObjects[this.event.button].src = newSrc;
            this.map.gameObjects[this.event.button].src_glow = newSrc;

            this.event.soundEffects.forEach((sfx,i) => {
                setTimeout(() => {
                    let howl = new Howl({
                        src: [sfx] // Может быть массивом
                     })
                    howl.play();
                }, 2000*i)
            })

            setTimeout(() => {
                this.map.gameObjects[this.event.button].gameObjectElement.src = prevSrc
                this.map.gameObjects[this.event.button].src = prevSrc;
                this.map.gameObjects[this.event.button].src_glow = prevSrc.replace(this.event.button, 
                        `${this.event.button}-glow`);
            }, this.event.playTimeOut)
            
            resolve();

        } else {
            this.event.soundEffects.forEach((sfx,i) => {
                setTimeout(() => {
                    let howl = new Howl({
                        src: [sfx] // Может быть массивом
                     })
                    howl.play();
                }, 2000*i)
            })
        }
    }

    removeInventoryCollections(resolve) {
        
        // if (!this.event.blocked) {
        //     this.map.quest.socket.emit("removeInventoryCollections", {type: "removeInventoryCollections", 
        //                                     collectableTypes:this.event.collectableTypes,
        //                                     blocked:true})
        // }

        this.event.collectableTypes.forEach(collectableType => {
            this.map.quest.inventory.removeInventoryCollection(collectableType);
        })

        resolve();
    }
    
    removeGameObjects(resolve) {

        Object.values(this.event.gameObjects).forEach(gameObj => {

            if (gameObj in this.map.quest.currentMap.gameObjects) {
                // console.log('starting to remove objects')
                this.map.quest.currentMap.removeObject(gameObj);
            }
    
            delete this.map.quest.mapStructure[this.event.map].gameObjects[gameObj];
        })

        resolve();
    }

    addGameObjects(resolve) {

        // console.log('ADDING GAME OBJECTS', this.event.gameObjects)

        // Добавляем объекты на карту, указанную в ивенте
        Object.values(this.event.gameObjects).forEach(gameObj => {
            if (this.event.map === this.map.name) {
                // Если карта та же, на которой мы, надо создать элемент на экране
                this.map.addObject(gameObj);
            } else {
                // Если это другая карта - достаточно добавить объект в общую структуру
                this.map.quest.mapStructure[this.event.map].gameObjects[gameObj.name] = new GameObject(gameObj);
            }
        })

        resolve();
    }

    async increaseCodeImage2(resolve) {
        // Версия без стрелок увеличения / уменьшения кода

        if (this.event.inventoryMenu) {
            this.event.inventoryMenu.increaseImage();
        } else {
            let element = document.getElementsByClassName(`codePuzzleImage-${this.event.index}`)[0];
            let currentIndex = parseInt(element.src.replace(`${process.env.REACT_APP_API_URL}`, '').split('.')[0].replace('-glow', '').slice(-1))
            let newIndex = (currentIndex + 1)%this.event.possibleValues.length;

            element.remove();

            // let newSrc = this.event.images[newIndex]

            let codePuzzleImage = new GameObject({
                name: `codePuzzleImage-${this.event.index}`,

                onClickEvents: [{type:'increaseCodeImage2', 
                                index: this.event.index, 
                                images: this.event.images, 
                                possibleValues: this.event.possibleValues,
                                map: this.map}],

                map: this.map,
                src: this.map.codeImages[newIndex],
                src_glow: this.map.codeImagesGlow[newIndex],
                x: this.map.x_coords[this.event.index],
                y: this.map.y_coords[this.event.index],
            })
            codePuzzleImage.init({map: this.map, container:this.map.element})

            // // Изменяем currentCode
            // Создаем функцию, чтобы безопасно менять стрингу currentCode
            String.prototype.replaceAt = function(index, replacement) {
                return this.substring(0, index) + replacement + this.substring(index + replacement.length);
            }

            this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
                this.map.possibleValues[newIndex]);

            // // В случае если код верный, переключаем карту в открытое состояние
            if (this.map.currentCode === this.map.correctCode) {
                if (this.map.redirectLocation) {
                    addRedirectMap(this.map.quest.code, `{"${this.map.name}" : "${this.map.redirectLocation}"}`);
                }
                
                this.map.quest.executeEvents(this.map.onSolveEvents)
            }
        }
        resolve();
    }

    async increaseCodeImage(resolve) {
        // Версия со стрелками увеличения / уменьшения кода
        
        if (this.event.inventoryMenu) {
            this.event.inventoryMenu.increaseImage();
        } else {
            let element = document.getElementsByClassName(`codePuzzleImage-${this.event.index}`)[0];
            let currentIndex = parseInt(element.src.replace(`${process.env.REACT_APP_API_URL}`, '').split('.')[0].replace('-glow', '').slice(-1))
            let newIndex = (currentIndex + 1)%this.event.possibleValues.length;
            element.src = this.event.images[newIndex] + '?t=123456789'

            // // Изменяем currentCode
            // Создаем функцию, чтобы безопасно менять стрингу currentCode
            String.prototype.replaceAt = function(index, replacement) {
                return this.substring(0, index) + replacement + this.substring(index + replacement.length);
            }

            this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
                this.map.possibleValues[newIndex]);

            // // В случае если код верный, переключаем карту в открытое состояние
            if (this.map.currentCode === this.map.correctCode) {
                if (this.map.redirectLocation) {
                    addRedirectMap(this.map.quest.code, `{"${this.map.name}" : "${this.map.redirectLocation}"}`);
                }
                
                this.map.quest.executeEvents(this.map.onSolveEvents)
            }
        }
        resolve();
    }

    async decreaseCodeImage(resolve) {
        if (this.event.inventoryMenu) {
            this.event.inventoryMenu.decreaseImage();
        } else {
            let element = document.getElementsByClassName(`codePuzzleImage-${this.event.index}`)[0];
            let currentIndex = parseInt(element.src.replace(`${process.env.REACT_APP_API_URL}`, '').split('.')[0].slice(-1))
            
            let newIndex = 0
            if (currentIndex - 1 >= 0) {
                newIndex = (currentIndex - 1)%this.event.possibleValues.length;
            } else {
                newIndex = this.event.possibleValues.length - 1;
            }
            element.src = this.event.images[newIndex] + '?t=123456789'

            // // Изменяем currentCode
            // Создаем функцию, чтобы безопасно менять стрингу currentCode
            String.prototype.replaceAt = function(index, replacement) {
                return this.substring(0, index) + replacement + this.substring(index + replacement.length);
            }
            
            this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
                this.map.possibleValues[newIndex]);

            // // В случае если код верный, переключаем карту в открытое состояние
            if (this.map.currentCode === this.map.correctCode) {
                if (this.map.redirectLocation) {
                    addRedirectMap(this.map.quest.code, `{"${this.map.name}" : "${this.map.redirectLocation}"}`);
                }
                
                this.map.quest.executeEvents(this.map.onSolveEvents)
            }
        }
        resolve();
    }
    
    async addRedirect(resolve) {
        if (!this.event.blocked) {
            addRedirectMap(this.map.quest.code, `{"${this.event.mapFrom}" : "${this.event.mapTo}"}`);
        }
        
        resolve();
    }

    async increaseCodeDigit(resolve) {
        // Достаем значения всех текстовых блоков
        // Берем тот текстовый блок, который соответствует индексу стрелки
        let element = this.map.element.querySelectorAll(".CodePuzzleText")[this.event.index]
        let s = element.textContent;
        let s_index = this.map.possibleValues.indexOf(s);

        // Создаем функцию, чтобы безопасно менять стрингу currentCode
        String.prototype.replaceAt = function(index, replacement) {
            return this.substring(0, index) + replacement + this.substring(index + replacement.length);
        };

        // Изменяем текстовый блок
        element.textContent = this.map.possibleValues[(s_index + 1)%this.map.possibleValues.length];
        
        // Изменяем currentCode
        this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
            this.map.possibleValues[(s_index + 1)%this.map.possibleValues.length]);

        // В случае если код верный, переключаем карту в открытое состояние
        if (this.map.currentCode === this.map.correctCode) {

            if (this.map.redirectLocation) {
                addRedirectMap(this.map.quest.code, `{"${this.map.name}" : "${this.map.redirectLocation}"}`);
            }
            
            this.map.quest.executeEvents(this.map.onSolveEvents)
        }
        resolve();
    }

    async decreaseCodeDigit(resolve) {
        // Достаем значения всех текстовых блоков
        // Берем тот текстовый блок, который соответствует индексу стрелки
        let element = this.map.element.querySelectorAll(".CodePuzzleText")[this.event.index]
        let s = element.textContent;
        let s_index = this.map.possibleValues.indexOf(s);

        // Создаем функцию, чтобы безопасно менять стрингу currentCode
        String.prototype.replaceAt = function(index, replacement) {
            return this.substring(0, index) + replacement + this.substring(index + replacement.length);
        }

        // Изменяем текстовый блок
        // Изменяем currentCode
        if (s_index > 0) {
            element.textContent = this.map.possibleValues[s_index - 1];
            this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
                 this.map.possibleValues[s_index - 1]);
        } else {
            element.textContent = this.map.possibleValues[this.map.possibleValues.length - 1];
            this.map.currentCode = this.map.currentCode.replaceAt(this.event.index,
                 this.map.possibleValues[this.map.possibleValues.length - 1]);
        }

        // В случае если код верный, переключаем карту в открытое состояние
        // // console.log(this.map.currentCode, this.map.correctCode)
        if (this.map.currentCode === this.map.correctCode) {
            if (this.map.redirectLocation) {
                addRedirectMap(this.map.quest.code, `{"${this.map.name}" : "${this.map.redirectLocation}"}`);
            }
            
            this.map.quest.executeEvents(this.map.onSolveEvents)
        }

        resolve();
    }

    syncCodes(resolve) {
        
        if (this.map.quest.mapStructure[this.event.mapName]['solvedCodes']) {
            this.map.quest.mapStructure[this.event.mapName]['solvedCodes'].push(this.event.code)
        } else {
            this.map.quest.mapStructure[this.event.mapName]['solvedCodes'] = []
            this.map.quest.mapStructure[this.event.mapName]['solvedCodes'].push(this.event.code)
        }

        this.map.quest.executeEvents([{type:'changeMap', mapFrom:this.event.mapName, throwOut:true, map: this.event.mapName}])
        this.map.quest.executeEvents(this.event.onSolveEvents);

        resolve();
    }

    enterCode(resolve) {
        // Мы храним все введенные цифры
        let s = this.map.currentCode + `${this.event.number}`
        this.map.currentCode = s;

        console.log(this.map.currentCode.slice(-this.map.maxCodeLength))

        // Но дисплеим только последние maxCodeLength
        if (this.map.displayCode) {
            let element = this.map.element.querySelectorAll(".CodePuzzleText")[0]
            element.textContent = this.map.currentCode.slice(-this.map.maxCodeLength);
        }

        for (let i=0; i<this.map.correctCodes.length; i++) {
            if (this.map.currentCode.slice(-this.map.maxCodeLength) === this.map.correctCodes[i]) {
                if (!(this.map.solvedCodes.includes(this.map.currentCode.slice(-this.map.maxCodeLength)))) {
                    this.map.quest.executeEvents([{type: 'syncCodes', mapName: this.event.map.name, code:s, onSolveEvents: this.map.onSolveEvents[i]}])
                    this.map.solvedCodes.push(this.map.currentCode.slice(-this.map.maxCodeLength))
                }
            }
        }

        let solved = true;

        this.map.correctCodes.forEach(code => {
            if (!this.map.solvedCodes.includes(code)) {
                // console.log('WE DO NOT HAVE THE CODE', code)
                solved = false;
            }
        })

        if (solved) {
            // console.log('SOLVED ALL CODES')
            this.map.quest.executeEvents(this.map.onSolvedAll);   
        }

        resolve();
        
    }

    openInventoryMenu(resolve) {
        let inventoryMenu = new InventoryMenu({
            inventoryCollection: this.event.inventoryCollection,
            map: this.map
        });

        let addContainer = document.querySelectorAll(`.QuestMap`)[0];
        inventoryMenu.init(addContainer);
        resolve();
    }

    openMapMenu(resolve) {
        this.map.quest.mapStructure['mapMenu'].backButtonRedirect = this.map.name;
        this.map.quest.executeEvents([{type: 'changeMap', map: 'mapMenu'}])

        resolve();
    }

    openHintsMenu(resolve) {
        let openHintsMenu = new HintsMenu({
            map: this.map
        });

        let addContainer = document.querySelectorAll(`.QuestMap`)[0];

        openHintsMenu.init(addContainer);
        resolve();
    }

    increaseHintsCounter(resolve) {

        this.map.quest.hintsStructure[this.event.stage].currentCounter += 1;

        while (this.map.quest.hintsStructure[this.map.quest.stage].currentCounter ===
            this.map.quest.hintsStructure[this.map.quest.stage].targetCounter
        ) {
            this.map.quest.stage += 1
            this.map.quest.hintsAsked = 0
        }

        if (this.event.throwOut) {
            this.map.quest.stage = this.event.stage + 1
            this.map.quest.hintsAsked = 0
        }

        resolve();
    }

    getNewHint(resolve) {

        if (!this.event.blocked) {
            try {
                // Увеличиваем количество спрошенных загадок
                if ((this.map.quest.hintsAsked < this.map.quest.hintsStructure[this.map.quest.stage]['hints'].length)) {
                    if ((this.event.socketId !== this.map.quest.socket.id)||(this.event.socketId === undefined)) {
                        this.map.quest.hintsAsked += 1;

                        if (document.querySelectorAll('.HintsMenu').length > 0) {
                            this.map.quest.executeEvents([{type:'changeMap', map: this.map.name}, {type:'openHintsMenu'}])
                        }

                    }
                }
            } catch (e) {
                console.error(e, e.stack)
            }

        } else {
            // console.log(this.event.socketId, this.map.quest.socket.id)
            if ((this.map.quest.hintsAsked < this.map.quest.hintsStructure[this.map.quest.stage]['hints'].length)) {
                if ((this.event.socketId !== this.map.quest.socket.id)||(this.event.socketId === undefined)) {
                    this.map.quest.hintsAsked += 1;
                    if (document.querySelectorAll('.HintsMenu').length > 0) {
                            this.map.quest.executeEvents([{type:'changeMap', map: this.map.name}, {type:'openHintsMenu'}])
                    }
                }
            }
        }

        
        resolve();
    }

    increaseHint(resolve) {
        this.event.hintsMenu.increaseHint();
        resolve();
    }

    decreaseHint(resolve) {
        this.event.hintsMenu.decreaseHint();
        resolve();
    }

    defaultEvent(resolve) {
        resolve();
    }

    increaseSound(resolve) {
        if (this.map.quest.soundVolume <= 90) {
            this.map.quest.soundVolume += 10;
        }

        document.querySelector(".fillContainerSound").style.width = `${this.map.quest.soundVolume}%`

        this.map.quest.music.volume(this.map.quest.soundVolume/1000)
        
        resolve();
    }

    decreaseSound(resolve) {
        if (this.map.quest.soundVolume >= 10) {
            this.map.quest.soundVolume -= 10;
        }

        document.querySelector(".fillContainerSound").style.width = `${this.map.quest.soundVolume}%`

        this.map.quest.music.volume(this.map.quest.soundVolume/1000)

        resolve();
    }

    init() {
        // console.log('Initiating Event:', this, this.event.type)

        // console.log('QUEST', this.map.quest)

        return new Promise(async resolve => {

            try {
                this[this.event.type](resolve);
            } catch (e) {
                console.log('ERROR IN THE EVENTS', e, e.stack)
                this.defaultEvent(resolve)
            }

            if (((['recursiveStyleChange', 'removeGameObjects', 'addGameObjects', 'changeLevelPicture', 'syncCodes',
            'enableMap', 'addInventory', 'removeInventoryCollections', 'getNewHint', 'increaseHintsCounter'].includes(this.event.type)) &&
                            (!this.event.blocked))||((this.event.throwOut) && (!this.event.blocked))) {

                // console.log('I AM STARTING TO SEND', this.map.quest.socket)
                // console.log(this.event)

                this.map.quest.socket.send(JSON.stringify({
                    id: this.map.quest.code,
                    method: 'questEvent',
                    event: {...this.event, blocked:true, socketId:this.map.quest.socket.id}
                }))

                // Вот эта штука привязывается к коду квеста, поэтому принципиально, чтобы он был не null
                // Если он вдруг null, то надо отправлять чела на страницу с login'ом насильно

                // let qe = await getQuestEvents(this.map.quest.code)
                let qe = this.event
                qe.blocked = true
                qe.socketId = this.map.quest.socket.id

                await addQuestEvent(this.map.quest.code, qe)

            }
        })
    };
}
