import JigsawPuzzle from "./JigsawPuzzle";
import LibraObject from "./LibraObject";

export default class Libra extends JigsawPuzzle {
    constructor(config) {
        super(config);
        // Должны знать, какие объекты размещать, и подхватить их из инвентаря
        this.collectableType = config.collectableType || 'stones';
        
        // Игровая область, куда можно ставить фигурки
        this.playableAreas = config.playableAreas || [
                                [[1625,  142], [1625+693, 142+1875]],
                                [[2894, 142], [2894+693, 142+1875]]
                                ]
        
        // На какие позиции ставить объект, когда курсор в playableAreas
        // Распределять вдоль весов,
        // указана крайняя левая и крайняя правая точка для каждого из состояний весов
        this.objectPositions = config.objectPositions || [
                                                        [[[1498, 1280], [2052, 1280]], // Libra-Left
                                                            [[2788, 1280], [3342, 1280]]], //Libra-Right
                                                        [[[1519, 1068], [2073, 1068]], // LibraUp-Left
                                                            [[2737, 1493], [3291, 1493]]], //LibraUp-Right
                                                        [[[1508, 1493], [2062, 1493]], // LibraDown-Left
                                                            [[2764, 1068], [3318, 1068]]], //LibraDown-Right
                                                        ]
        
        // В каком состоянии сейчас находятся весы
        this.mapState = config.mapState || 0;

        // Какой это соответствует картинке
        this.mapStatePictures = config.mapStatePictures || [`${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/pandaTempleDay_Libra.${process.env.REACT_APP_BIF}`,
                                    `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/pandaTempleDay_LibraUp.${process.env.REACT_APP_BIF}`,
                                    `${process.env.REACT_APP_API_URL}pandaQuest/images/backgrounds/pandaTempleDay_LibraDown.${process.env.REACT_APP_BIF}`]

        // Корректные веса
        this.correctWeights = config.correctWeights || [16, 16]
        this.currentWeights = [0,0];
        this.libraObjects = [{}, {}];

        // Потенциально уже могут быть кусочки паззла для подсказки
        this.gameObjects = config.gameObjects || {};

        // Что происходит, когда паззл решен
        this.onSolveEvents = config.onSolveEvents || [
            {type:'addRedirect', mapTo: "pandaTempleDay_LibraSolved", mapFrom: "pandaTempleDay_Libra"},
            {type:'changeMap', map: "pandaTempleDay_LibraSolved", throwOut:true, mapFrom:'pandaTempleDay_Libra'},
            {type:'removeInventoryCollections', collectableTypes:['stones']},
            {type:'increaseHintsCounter', stage:11, throwOut:true}] // Тут надо поставить нормальные события

        this.draggingObject = null;
    }

    mountObjects() {
        // Функция достает объекты из инвентаря в область инициализации
        let inventoryCollections = this.quest.inventory.inventoryCollections;
        for (let i=0; i < inventoryCollections.length; i++) {
            if (inventoryCollections[i].collectableType === this.collectableType) {
                Object.keys(inventoryCollections[i].gameObjects).forEach(key => {
                    this.gameObjects[key] = inventoryCollections[i].gameObjects[key];
                });
            };
        };

        // Сортируем объекты для отображения в поле инициализации, сохраняем упорядоченный массив
        let gameObjectsOrdered = []
        for (var key in this.gameObjects) {
            gameObjectsOrdered.push([key, this.gameObjects[key]['order']]);
        }
        gameObjectsOrdered.sort(function(a, b) {
            return a[1] - b[1];
        });
        this.gameObjectsOrdered = gameObjectsOrdered;

        // console.log('GAMEOBJECTS', this.gameObjects)

        // Отображаем объекты в поле инициализации        
        for (let i=0; i < gameObjectsOrdered.length; i++) {
            let workingGameObject = this.gameObjects[gameObjectsOrdered[i][0]]
            // console.log(workingGameObject.name, workingGameObject.collectableType);

            // Как-то инициализируем объект
            let jigsawObject = 1;

            if (!this.customInitialization) {
                jigsawObject = new LibraObject({
                    name: workingGameObject.name,
                    weight: workingGameObject.weight,
                    collectableType: workingGameObject.collectableType,
                    x: this.xInitialization + this.initializationGapSize + 
                            (this.initializationGapSize + this.initializationPictureWidth) * i,
                    y: this.yInitialization + this.initializationGapSize,
                });
            } else {
                jigsawObject = new LibraObject({
                    name: workingGameObject.name,
                    weight: workingGameObject.weight,
                    collectableType: workingGameObject.collectableType,
                    x: this.customInitializationPositions[i][0],
                    y: this.customInitializationPositions[i][1],
                });
            }

            // Инициализируем объект паззла
            jigsawObject.init({
                map:this,
                container:this.element,
            });

            
            
            // Создаем отдельный массив с объектами паззла
            // console.log(jigsawObject);
            // this.jigsawObjects[jigsawObject.name] = jigsawObject;
        }

    }

}