import React, {useContext, useState} from 'react';
import {Card, Container, Form, Button} from "react-bootstrap";
import { useHistory} from "react-router-dom";
import { login } from '../http/userAPI';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { GAME_ROUTE } from '../utils/consts';
// import {user} from '../store/UserStore';

const Auth = observer(() => {

    const {user} = useContext(Context)

    const history = useHistory()

    const [questCode, setQuestCode] = useState()
    const [name, setName] = useState()

    const signIn = async () => {

        try {
            // let data;
            let {foundQuest, uuid} = await login(questCode, name)

            user.setIsAuth(true)

            history.push(`${GAME_ROUTE}/${foundQuest}/${questCode}?uuid=${uuid}`)

        } catch (e) {
            // Алерт для пользователя, если проскочила какая-то ошибка
            // alert(e.response.data.message)
            alert(e)
        }   
    }

  return (
    <Container 
        className="d-flex justify-content-center align-items-center"
        style={{height: window.innerHeight - 54}}
    >
        <Card style={{width:600}} className='p-5'>
            <h2 className='m-auto'>АВТОРИЗАЦИЯ</h2>
            <Form className='d-flex flex-column'>
                <Form.Control 
                    className='mt-3'
                    placeholder='Введите код'
                    value = {questCode}
                    onChange = {e => setQuestCode(e.target.value)}
                />
                <Form.Control 
                    className='mt-3'
                    placeholder='Введите имя'
                    value = {name}
                    onChange = {e => setName(e.target.value)}
                />
                
                <Button 
                    onClick = {signIn}
                    className='mt-3 align-self-end'
                    variant={'outline-success'}
                >Войти</Button>
            </Form>
        </Card>
    </Container>
  );
})

export default Auth;