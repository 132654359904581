import QuestMap from "./QuestMap";
import GameObject from "./GameObject";

export default class MapPuzzle extends QuestMap {
    constructor(config) {
        super(config);
        
        // Информация о том, решена ли загадка
        this.isSolved = false;
        this.onSolveEvents = config.onSolveEvents || [
            {type:'enableMap', redirectMap: 'pandaTempleDay', questMap: 'grandpaCabinet1_Map'},
            {type:'removeGameObjects', map: "grandpaCabinet1", gameObjects: ["map"]},
            {type:'increaseHintsCounter', stage:5, throwOut:true},
            {type:'removeInventoryCollections', collectableTypes:['page', 'pandaPictures']},
            ]

        // Информация о коде
        this.correctCoords = config.correctCoords || [24,10]; // 17,5
        this.gridX = config.gridX || 28;
        this.gridY = config.gridY || 17;
        this.imgGlow = config.ingGlow || `${process.env.REACT_APP_API_URL}pandaQuest/images/puzzles/mapPuzzleGlow.${process.env.REACT_APP_GOF}`

        this.leftGrid = config.leftGrid || 423;
        this.topGrid = config.topGrid || 174;
        this.gridWidth = config.gridWidth || 80 + 28; // Ширина сетки + расстояние между

        this.gameObjects = [];
    }

    createGameObjects() {
        this.playableAreas = []
        for (let i=0;i<this.gridX;i++) {
            for (let j=0;j<this.gridY;j++) {
                let gameObj = 0;
                if (i !== this.correctCoords[0] && j !== this.correctCoords[1]) {
                    gameObj = new GameObject({
                        name: `mapGrid-${i}-${j}`,
                        src: `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/grandpaCabinet1_Map-mapPuzzle.${process.env.REACT_APP_GOF}`,
                        src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/grandpaCabinet1_Map-mapPuzzle-glow.${process.env.REACT_APP_GOF}`,
                        x: this.leftGrid + this.gridWidth * i,
                        y: this.topGrid + this.gridWidth * j,
                    })
                } else {
                    gameObj = new GameObject({
                        name: `mapGrid-${i}-${j}`,
                        src: `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/grandpaCabinet1_Map-mapPuzzle.${process.env.REACT_APP_GOF}`,
                        src_glow: `${process.env.REACT_APP_API_URL}pandaQuest/images/objects/grandpaCabinet1_Map-mapPuzzle-glow.${process.env.REACT_APP_GOF}`,
                        onClickEvents: this.onSolveEvents,
                        x: this.leftGrid + this.gridWidth * i,
                        y: this.topGrid + this.gridWidth * j,
                    })
                }
                this.gameObjects.push(gameObj);
                
            }
        }
    };

    init(container) {
        this.createGameObjects();
        this.createElement();
        container.insertBefore(this.element, container.firstChild);

        this.mountObjects();
        this.addNavigationElements();
    };
}